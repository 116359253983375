import {
  DEFAULT_COL,
  DEFAULT_DELAY,
  DEFAULT_DENSITY,
  DEFAULT_PATH_FINDING_ALGO,
  DEFAULT_ROW,
} from 'pages/path-finding-page/config/Constants';
import { randomMap, randomStartEndPosition } from 'pages/path-finding-page//config/functions/random';

import PathFindingState from 'pages/path-finding-page/config/interfaces/PathFindingState';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import RunningState from 'pages/path-finding-page/config/interfaces/RunningState';
import RunningStatus from '../config/enums/RunningStatus';
import SettingParams from 'pages/path-finding-page/config/interfaces/SettingParams';
import pathFindingAlgo from 'pages/path-finding-page/config/path-finding-algorithms/pathFindingAlgo';

const initialStateSettingParams = {
  cols: DEFAULT_COL,
  rows: DEFAULT_ROW,
  obstacleDensity: DEFAULT_DENSITY,
  delay: DEFAULT_DELAY,
  pathFindingAlgo: DEFAULT_PATH_FINDING_ALGO,
  disabled: false,
} as SettingParams;

const map = randomMap(DEFAULT_ROW, DEFAULT_COL, DEFAULT_DENSITY);
const startPosition = randomStartEndPosition(map);
const endPosition = randomStartEndPosition(map, startPosition);

const initialRouteMap = {
  map,
  startPosition,
  endPosition,
  path: [],
  touchPosition: null,
} as RouteMap;

const initialRunningState = {
  status: RunningStatus.FINISHED,
  currentStep: -1,
  findingSteps: [],
} as RunningState;

const { routeMap, runningState } = pathFindingAlgo(
  initialStateSettingParams.pathFindingAlgo,
  initialRouteMap,
  initialRunningState
);

export const initialState = {
  settingParams: initialStateSettingParams,
  routeMap: routeMap,
  runningState: runningState,
} as PathFindingState;
