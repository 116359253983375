import NeighborRoutes from 'pages/path-finding-page/config/interfaces/NeighborRoutes';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import RunningState from 'pages/path-finding-page/config/interfaces/RunningState';
import distanceBetweenTwoPosition from 'pages/path-finding-page/config/functions/distanceBetweenTwoPosition';
import isCurrentPositionOptimized from 'pages/path-finding-page/config/path-finding-algorithms/isCurrentPositionOptimized';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';
import optimizeDistance from 'pages/path-finding-page/config/path-finding-algorithms/optimizeDistance';

export default function lightDevopsAlgo(routeMap: RouteMap, runningState: RunningState) {
  const queue = {
    primary: [] as Position[],
    secondary: [] as Position[],
  };

  if (routeMap.startPosition) queue.primary.push(routeMap.startPosition);

  whileloop: while (queue.primary.length > 0) {
    const currentPosition = queue.primary.shift();

    if (currentPosition) {
      currentPosition.visited = false;

      if (isSamePosition(currentPosition, routeMap.endPosition)) {
        runningState.findingSteps.push(currentPosition);
        break whileloop;
      }

      const neighborRoutes = getFilteredNeighborRoutes(currentPosition, routeMap);

      neighborRoutes.primary.forEach((neighborPosition) => {
        optimizeDistance(neighborPosition, currentPosition);
        addUnvisitedPosition(neighborPosition, queue.primary);
      });

      neighborRoutes.secondary.forEach((neighborPosition) => {
        optimizeDistance(neighborPosition, currentPosition);
        addUnvisitedPosition(neighborPosition, queue.secondary);
      });
    }

    if (queue.primary.length === 0) {
      queue.primary = queue.secondary;
      queue.secondary = [];
    }

    if (currentPosition) {
      runningState.findingSteps.push(currentPosition);
    }
  }
}

function getFilteredNeighborRoutes(currentPosition: Position, routeMap: RouteMap) {
  const neighborRoutes = {
    primary: [] as Position[],
    secondary: [] as Position[],
  } as NeighborRoutes;

  let neighborPosition: Position;

  if (routeMap.endPosition) {
    for (let rowIncrement = -1; rowIncrement < 2; rowIncrement++) {
      for (let colIncrement = -1; colIncrement < 2; colIncrement++) {
        if (
          (rowIncrement !== 0 || colIncrement !== 0) &&
          currentPosition.row + rowIncrement >= 0 &&
          currentPosition.row + rowIncrement < routeMap.map.length &&
          currentPosition.col + colIncrement >= 0 &&
          currentPosition.col + colIncrement < routeMap.map[0].length
        ) {
          neighborPosition = routeMap.map[currentPosition.row + rowIncrement][currentPosition.col + colIncrement];
          if (
            !isCurrentPositionOptimized(neighborPosition, currentPosition) &&
            !isSamePosition(neighborPosition, routeMap.startPosition)
          ) {
            if (
              distanceBetweenTwoPosition(neighborPosition, routeMap.endPosition) <
              distanceBetweenTwoPosition(currentPosition, routeMap.endPosition)
            )
              neighborRoutes.primary.push(neighborPosition);
            else neighborRoutes.secondary.push(neighborPosition);
          }
        }
      }
    }
  }

  return neighborRoutes;
}

function addUnvisitedPosition(position: Position, queue: Position[]) {
  if (position.visited === false) {
    position.visited = true;
    queue.push(position);
  }
}
