interface Props {
  label: any;
  type: string;
  placeholder?: string;
  value: string | number;
  onChange: Function;
  disabled?: boolean;
  min?: number;
  max?: number;
}

function Input({ label, type, placeholder, value, onChange, disabled, min, max }: Props) {
  return (
    <div className='flex flex-col w-full items-center md:items-start md:mb-2'>
      <label className='text-gray-800 font-semibold block mb-1 text-md'>{label}</label>
      <input
        className='text-center w-full md:text-start bg-gray-100 px-4 py-1 rounded-lg focus:outline-none'
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        placeholder={placeholder}
        min={min}
        max={max}
      />
    </div>
  );
}

export default Input;
