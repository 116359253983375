import Colors from 'pages/sorting-page/config/enums/Colors';
import ColumnList from 'pages/sorting-page/components/display/ColumnList';
import SideBar from 'pages/sorting-page/components/display/SideBar';
import { selectTimer } from 'pages/sorting-page/states/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const formatTime = (time: number) => ('0' + time).slice(-2);

function SortingPage() {
  const time = useSelector(selectTimer);

  const timeString = useMemo(() => {
    const diffTime = time.endTime - time.startTime - time.timePaused;
    const ms = diffTime % 1000;
    const s = Math.floor(diffTime / 1000) % 60;
    const m = Math.floor(diffTime / 1000 / 60) % 60;
    const h = Math.floor(diffTime / 1000 / 60 / 60);

    return `${formatTime(h)} : ${formatTime(m)} : ${formatTime(s)}.${ms}`;
  }, [time]);

  return (
    <div className='flex flex-col md:flex-row items-start md:items-center space-x-10 md:mx-16 space-y-10 md:space-y-0'>
      <h1 className='md:hidden text-center text-2xl my-6 ml-10 text-gray-600 font-bold font-sans'>Sorting Algo</h1>

      <div className='flex order-last md:order-none'>
        <SideBar />
      </div>
      <div className='flex flex-col items-start space-y-10'>
        <ColumnList />
        <div className='flex space-x-10 items-center text-lg font-semibold font-sans'>
          <div className={`${Colors.BG_CURRENT_MOVE} p-2`}>Current Swap</div>
          <div className={`${Colors.BG_PREVIOUS_MOVE} text-white p-2`}>Previous Swap</div>
          <div>{timeString}</div>
        </div>
      </div>
    </div>
  );
}

export default SortingPage;
