import Button from 'common/components/Button';
import { PauseIcon } from 'pages/path-finding-page/config/Icons';
import { pause } from 'pages/path-finding-page/states/pathFindingSlice';
import { useDispatch } from 'react-redux';

function ButtonPause() {
  const dispatch = useDispatch();

  const handleOnClickPause = () => {
    dispatch(pause());
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<PauseIcon color='primary' />}
      title='Pause'
      onClick={handleOnClickPause}
    />
  );
}

export default ButtonPause;
