import LegendList from 'pages/path-finding-page/components/display/LegendList';
import RouteMapDisplay from 'pages/path-finding-page/components/display/RouteMapDisplay';
import SideBar from 'pages/path-finding-page/components/display/SideBar';

function PathFindingPage() {
  return (
    <div className='flex flex-col mt-10 md:flex-row items-start md:items-center md:space-x-10 md:mx-16 space-y-10 md:space-y-0'>
      <h1 className='md:hidden text-center text-2xl my-6 ml-10 text-gray-600 font-bold font-sans'>Path Finding</h1>
      <SideBar />
      <div className='flex flex-col space-y-5 m-5'>
        <LegendList />
        <RouteMapDisplay />
      </div>
    </div>
  );
}

export default PathFindingPage;
