import ButtonPause from 'pages/sorting-page/components/features/buttons/ButtonPause';
import ButtonPlay from 'pages/sorting-page/components/features/buttons/ButtonPlay';
import TimerStatus from 'pages/sorting-page/config/enums/TimerStatus';
import { selectTimer } from 'pages/sorting-page/states/selectors';
import { useSelector } from 'react-redux';

function ButtonPlayPause() {
  const timer = useSelector(selectTimer);
  return (
    <>
      {timer.status === TimerStatus.Running ? <ButtonPause /> : <ButtonPlay />}
    </>
  );
}

export default ButtonPlayPause;
