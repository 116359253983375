import SortingArray from 'pages/sorting-page/config/interfaces/SortingArray';

const swap = (arr: SortingArray, i: number, j: number) => {
  if (i !== j) {
    arr.indexSwapArray.push({
      firstIndex: i,
      secondIndex: j,
    });
    const temp = arr.sortedArray[i];
    arr.sortedArray[i] = arr.sortedArray[j];
    arr.sortedArray[j] = temp;
  }
};

export default swap;
