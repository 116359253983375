import ButtonNext from 'pages/sorting-page/components/features/buttons/ButtonNext';
import ButtonPlayPause from 'pages/sorting-page/components/features/buttons/ButtonPlayPause';
import ButtonPrev from 'pages/sorting-page/components/features/buttons/ButtonPrev';
import ButtonReset from 'pages/sorting-page/components/features/buttons/ButtonReset';

function ControllerButtons() {
  return (
    <div className='flex flex-col order-first md:order-none'>
      <div className='flex justify-center items-center mt-6 space-x-5'>
        <ButtonPrev />
        <ButtonPlayPause />
        <ButtonNext />
      </div>
      <div className='flex justify-center my-3'>
        <ButtonReset />
      </div>
    </div>
  );
}

export default ControllerButtons;
