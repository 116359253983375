import {
  prestartTimer,
  resumeTimer,
  runTimer,
  setDisabled,
  setTimerStatus,
  sortArray,
} from 'pages/sorting-page/states/sortingSlice';
import {
  selectSettingParams,
  selectSortingArray,
  selectTimer,
} from 'pages/sorting-page/states/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { PlayIcon } from 'pages/sorting-page/config/Icons';
import TimerStatus from 'pages/sorting-page/config/enums/TimerStatus';

function ButtonPlay() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);
  const sortingArray = useSelector(selectSortingArray);
  const timer = useSelector(selectTimer);

  const handleOnClickPlay = () => {
    if (
      sortingArray.indexSwapArray.length === 0 ||
      sortingArray.currentIndexSwap < sortingArray.indexSwapArray.length
    ) {
      if (!settingParams.disabled) dispatch(setDisabled(true));

      if (timer.status === TimerStatus.Stopped) {
        dispatch(prestartTimer());
        dispatch(setTimerStatus(TimerStatus.Running));
        dispatch(sortArray(settingParams));
      } else if (timer.status === TimerStatus.Paused) {
        dispatch(resumeTimer());
        dispatch(setTimerStatus(TimerStatus.Running));
      }

      const newIntervalId: NodeJS.Timer = setInterval(() =>
        dispatch(runTimer(newIntervalId))
      );
    }
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<PlayIcon color='primary' />}
      title='Play'
      onClick={handleOnClickPlay}
    />
  );
}

export default ButtonPlay;
