import {
  addElement,
  deleteLastElement,
  pauseTimer,
  setCurrentIndexSwap,
  swapIndex,
} from 'pages/sorting-page/states/sortingSlice';
import {
  selectSettingParams,
  selectSortingArray,
  selectTimer,
} from 'pages/sorting-page/states/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Colors from 'pages/sorting-page/config/enums/Colors';
import Column from 'pages/sorting-page/components/display/Column';
import TimerStatus from 'pages/sorting-page/config/enums/TimerStatus';
import { useEffect } from 'react';

function ColumnList() {
  const settingParams = useSelector(selectSettingParams);
  const sortingArray = useSelector(selectSortingArray);
  const timer = useSelector(selectTimer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      timer.status === TimerStatus.Running &&
      sortingArray.indexSwapArray.length > 0 &&
      sortingArray.currentIndexSwap < sortingArray.indexSwapArray.length
    ) {
      setTimeout(() => {
        dispatch(
          swapIndex(sortingArray.indexSwapArray[sortingArray.currentIndexSwap])
        );
        dispatch(setCurrentIndexSwap(sortingArray.currentIndexSwap + 1));
      }, settingParams.delay);
    } else if (
      sortingArray.currentIndexSwap > 0 &&
      sortingArray.currentIndexSwap >= sortingArray.indexSwapArray.length
    ) {
      if (timer.intervalId) clearInterval(timer.intervalId);
      dispatch(pauseTimer());
    }
  }, [
    sortingArray.indexSwapArray,
    sortingArray.currentIndexSwap,
    timer.status,
  ]);

  useEffect(() => {
    const diff = settingParams.columns - sortingArray.initArray.length;
    if (diff > 0) {
      for (let i = 0; i < diff; i++)
        dispatch(
          addElement(Math.floor(Math.random() * settingParams.maxHeight))
        );
    } else if (diff < 0) {
      for (let i = 0; i < -diff; i++) dispatch(deleteLastElement());
    }
  }, [settingParams.columns]);

  const getBgColor = (index: number) =>
    sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1] &&
    (index ===
      sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1]
        .firstIndex ||
      index ===
        sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1]
          .secondIndex)
      ? Colors.BG_PREVIOUS_MOVE
      : sortingArray.indexSwapArray[sortingArray.currentIndexSwap] &&
        (index ===
          sortingArray.indexSwapArray[sortingArray.currentIndexSwap]
            .firstIndex ||
          index ===
            sortingArray.indexSwapArray[sortingArray.currentIndexSwap]
              .secondIndex)
      ? Colors.BG_CURRENT_MOVE
      : Colors.BG_DEFAULT;

  const getBorderColor = (index: number) =>
    sortingArray.indexSwapArray[sortingArray.currentIndexSwap] &&
    (index ===
      sortingArray.indexSwapArray[sortingArray.currentIndexSwap].firstIndex ||
      index ===
        sortingArray.indexSwapArray[sortingArray.currentIndexSwap].secondIndex)
      ? Colors.BORDER_CURRENT_MOVE
      : sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1] &&
        (index ===
          sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1]
            .firstIndex ||
          index ===
            sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1]
              .secondIndex)
      ? Colors.BORDER_PREVIOUS_MOVE
      : Colors.BORDER_DEFAULT;

  return (
    <div className='flex items-end space-x-1'>
      {sortingArray.initArray.map((height, i) => (
        <Column
          key={i}
          height={height}
          color={getBgColor(i)}
          borderColor={getBorderColor(i)}
        />
      ))}
    </div>
  );
}

export default ColumnList;
