import { BOX_PIXEL, BOX_SIZE } from 'pages/path-finding-page/config/Constants';
import { DragEvent, TouchEvent, useState } from 'react';
import { editTouchPosition, moveStartPosition } from 'pages/path-finding-page/states/pathFindingSlice';
import { useDispatch, useSelector } from 'react-redux';

import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import { StartPositionIcon } from 'pages/path-finding-page/config/Icons';
import TouchPosition from 'pages/path-finding-page/config/enums/TouchPosition';
import { selectRouteMap } from 'pages/path-finding-page/states/selectors';

function StartPosition() {
  const [initXY, setInitXY] = useState({ row: 0, col: 0 });
  const dispatch = useDispatch();
  const routeMap = useSelector(selectRouteMap);

  const handleOnTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    dispatch(editTouchPosition(routeMap.startPosition));
  };

  const handleOnDragStart = (e: DragEvent<HTMLDivElement>) => {
    setInitXY({
      row: e.clientY,
      col: e.clientX,
    });
  };
  const handleOnDragEnd = (e: DragEvent<HTMLDivElement>) => {
    const endXY = {
      row: e.clientY,
      col: e.clientX,
    };

    if (routeMap.startPosition)
      dispatch(
        moveStartPosition({
          row: routeMap.startPosition.row + Math.round((endXY.row - initXY.row) / (BOX_PIXEL + 1)),
          col: routeMap.startPosition.col + Math.round((endXY.col - initXY.col) / (BOX_PIXEL + 1)),
          color: routeMap.startPosition.color,
          value: routeMap.startPosition.value,
          visited: false,
        })
      );
  };
  return (
    <div
      className={`flex justify-center items-center ${BOX_SIZE} ${
        routeMap.touchPosition === TouchPosition.START_POSITION
          ? MapColors.BG_TOUCH_POSITION
          : MapColors.BG_START_POSITION
      }`}
      draggable={true}
      onDragStart={(e) => handleOnDragStart(e)}
      onDragEnd={(e) => handleOnDragEnd(e)}
      onTouchStart={(e) => handleOnTouchStart(e)}>
      <StartPositionIcon fontSize='inherit' />
    </div>
  );
}

export default StartPosition;
