import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import RunningState from 'pages/path-finding-page/config/interfaces/RunningState';
import RunningStatus from 'pages/path-finding-page/config/enums/RunningStatus';

function resetState(routeMap: RouteMap, runningState: RunningState) {
  routeMap.map.forEach((row) =>
    row.forEach((position) => {
      position.value = position.value !== MapValues.WALL ? MapValues.ROUTE : MapValues.WALL;
      position.color = position.color !== MapColors.BG_WALL ? MapColors.BG_ROUTE : MapColors.BG_WALL;
      position.visited = false;
    })
  );
  routeMap.path = [];
  if (routeMap.startPosition) {
    routeMap.startPosition.value = MapValues.ROUTE;
    routeMap.startPosition.color = MapColors.BG_ROUTE;
    routeMap.startPosition.visited = false;
  }
  if (routeMap.endPosition) {
    routeMap.endPosition.value = MapValues.ROUTE;
    routeMap.endPosition.color = MapColors.BG_ROUTE;
    routeMap.endPosition.visited = false;
  }

  runningState.currentStep = -1;
  runningState.findingSteps = [];
  runningState.status = RunningStatus.FINISHED;
}

export default resetState;
