import { DragEvent, TouchEvent, useState } from 'react';
import { editTouchPosition, moveEndPosition, moveStartPosition } from 'pages/path-finding-page/states/pathFindingSlice';
import { useDispatch, useSelector } from 'react-redux';

import { BOX_SIZE } from 'pages/path-finding-page/config/Constants';
import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import TouchPosition from 'pages/path-finding-page/config/enums/TouchPosition';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';
import { selectRouteMap } from 'pages/path-finding-page/states/selectors';

function BoxPosition({ cell }: { cell: Position }) {
  const dispatch = useDispatch();
  const routeMap = useSelector(selectRouteMap);

  const handleOnTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    if (routeMap.endPosition != null && routeMap.touchPosition === TouchPosition.END_POSITION) {
      dispatch(
        moveEndPosition({
          row: cell.row,
          col: cell.col,
          color: routeMap.endPosition.color,
          value: routeMap.endPosition.value,
          visited: false,
        })
      );
    } else if (routeMap.startPosition != null && routeMap.touchPosition === TouchPosition.START_POSITION) {
      dispatch(
        moveStartPosition({
          row: cell.row,
          col: cell.col,
          color: routeMap.startPosition.color,
          value: routeMap.startPosition.value,
          visited: false,
        })
      );
    }

    dispatch(editTouchPosition(null));
  };

  return (
    <div
      className={`flex justify-center items-center ${BOX_SIZE} ${cell.color}`}
      onTouchStart={(e) => handleOnTouchStart(e)}></div>
  );
}

export default BoxPosition;
