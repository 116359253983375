import {
  selectSettingParams,
  selectSortingArray,
  selectTimer,
} from 'pages/sorting-page/states/selectors';
import {
  setCurrentIndexSwap,
  setDisabled,
  setEndTime,
  swapIndex,
} from 'pages/sorting-page/states/sortingSlice';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { PreviousIcon } from 'pages/sorting-page/config/Icons';
import TimerStatus from 'pages/sorting-page/config/enums/TimerStatus';

function ButtonPrev() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);
  const sortingArray = useSelector(selectSortingArray);
  const timer = useSelector(selectTimer);

  const handleOnClickPrev = () => {
    if (timer.status === TimerStatus.Paused) {
      if (!settingParams.disabled) dispatch(setDisabled(true));

      if (sortingArray.currentIndexSwap > 0) {
        dispatch(
          swapIndex(
            sortingArray.indexSwapArray[sortingArray.currentIndexSwap - 1]
          )
        );
        dispatch(setCurrentIndexSwap(sortingArray.currentIndexSwap - 1));

        dispatch(
          setEndTime(
            timer.endTime -
              Math.floor(
                (timer.endTime - timer.startTime - timer.timePaused) /
                  sortingArray.currentIndexSwap
              )
          )
        );
      }
    }
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<PreviousIcon color='action' />}
      title='Previous swap'
      onClick={handleOnClickPrev}
    />
  );
}

export default ButtonPrev;
