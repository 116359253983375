import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { randomMap, randomPosition } from 'pages/path-finding-page/config/functions/random';

import { MIN_ROW_COL } from 'pages/path-finding-page/config/Constants';
import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import PathFindingAlgo from 'pages/path-finding-page/config/enums/PathFindingAlgo';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import RunningStatus from 'pages/path-finding-page/config/enums/RunningStatus';
import TouchPosition from '../config/enums/TouchPosition';
import checkAndResetStartEndPosition from './checkAndResetStartEndPosition';
import { initialState } from 'pages/path-finding-page/states/initialStates';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';
import pathFindingAlgo from 'pages/path-finding-page/config/path-finding-algorithms/pathFindingAlgo';

export const pathFindingSlice = createSlice({
  name: 'pathFinding',
  initialState,
  reducers: {
    // SETTING FEATURES
    editCols: (state, action: PayloadAction<number>) => {
      if (action.payload >= MIN_ROW_COL) {
        const numberNewCols = action.payload - state.settingParams.cols;

        if (numberNewCols > 0) {
          state.routeMap.map.map((row, rowIndex) => {
            for (let i = 0; i < numberNewCols; i++) {
              row.push(randomPosition(state.settingParams.obstacleDensity, rowIndex, row.length + i));
            }
          });
        } else if (numberNewCols < 0) {
          state.routeMap.map.map((row) => {
            row.splice(numberNewCols, -numberNewCols);
          });
        }

        state.routeMap = checkAndResetStartEndPosition(state.routeMap);

        const { routeMap, runningState } = pathFindingAlgo(
          state.settingParams.pathFindingAlgo,
          state.routeMap,
          state.runningState
        );
        state.routeMap = routeMap;
        state.runningState = runningState;

        state.settingParams.cols = action.payload;
      }
    },

    // SETTING FEATURES
    editRows: (state, action: PayloadAction<number>) => {
      if (action.payload >= MIN_ROW_COL) {
        const numberNewRows = action.payload - state.settingParams.rows;

        if (numberNewRows > 0) {
          for (let i = 0; i < numberNewRows; i++) {
            let row = [];
            for (let j = 0; j < state.settingParams.cols; j++) {
              row.push(randomPosition(state.settingParams.obstacleDensity, state.settingParams.rows + i, j));
            }
            state.routeMap.map.push(row);
          }
        } else if (numberNewRows < 0) {
          state.routeMap.map.splice(numberNewRows, -numberNewRows);
        }

        state.routeMap = checkAndResetStartEndPosition(state.routeMap);

        const { routeMap, runningState } = pathFindingAlgo(
          state.settingParams.pathFindingAlgo,
          state.routeMap,
          state.runningState
        );
        state.routeMap = routeMap;
        state.runningState = runningState;

        state.settingParams.rows = action.payload;
      }
    },

    // SETTING FEATURES
    editObstacleDensity: (state, action: PayloadAction<number>) => {
      state.settingParams.obstacleDensity = action.payload;

      state.routeMap.map = randomMap(
        state.settingParams.rows,
        state.settingParams.cols,
        state.settingParams.obstacleDensity
      );

      state.routeMap = checkAndResetStartEndPosition(state.routeMap);

      const { routeMap, runningState } = pathFindingAlgo(
        state.settingParams.pathFindingAlgo,
        state.routeMap,
        state.runningState
      );
      state.routeMap = routeMap;
      state.runningState = runningState;
    },

    // SETTING FEATURES
    setDelay: (state, action: PayloadAction<number>) => {
      state.settingParams.delay = action.payload;
    },

    // SETTING FEATURES
    setPathFindingAlgo: (state, action: PayloadAction<PathFindingAlgo>) => {
      state.settingParams.pathFindingAlgo = action.payload;

      const { routeMap, runningState } = pathFindingAlgo(
        state.settingParams.pathFindingAlgo,
        state.routeMap,
        state.runningState
      );
      state.routeMap = routeMap;
      state.runningState = runningState;
    },

    // DISPLAY FEATURES
    editTouchPosition: (state, action: PayloadAction<Position | null>) => {
      let touchPosition: TouchPosition | null = null;
      if (isSamePosition(action.payload, state.routeMap.startPosition)) {
        touchPosition = TouchPosition.START_POSITION;
      } else if (isSamePosition(action.payload, state.routeMap.endPosition)) {
        touchPosition = TouchPosition.END_POSITION;
      } else {
        touchPosition = null;
      }

      state.routeMap.touchPosition = touchPosition;
    },

    // DISPLAY FEATURES
    moveStartPosition: (state, action: PayloadAction<Position>) => {
      //check if position is not Wall and is not end position
      if (
        state.routeMap.map[action.payload.row][action.payload.col].value !== MapValues.WALL &&
        !isSamePosition(state.routeMap.map[action.payload.row][action.payload.col], state.routeMap.endPosition)
      ) {
        state.routeMap.startPosition = action.payload;

        const { routeMap, runningState } = pathFindingAlgo(
          state.settingParams.pathFindingAlgo,
          state.routeMap,
          state.runningState
        );
        state.routeMap = routeMap;
        state.runningState = runningState;
      }
    },

    // DISPLAY FEATURES
    moveEndPosition: (state, action: PayloadAction<Position>) => {
      //check if position is not Wall and is not start position
      if (
        state.routeMap.map[action.payload.row][action.payload.col].value !== MapValues.WALL &&
        !isSamePosition(state.routeMap.map[action.payload.row][action.payload.col], state.routeMap.startPosition)
      ) {
        state.routeMap.endPosition = action.payload;

        const { routeMap, runningState } = pathFindingAlgo(
          state.settingParams.pathFindingAlgo,
          state.routeMap,
          state.runningState
        );
        state.routeMap = routeMap;
        state.runningState = runningState;
      }
    },

    // BUTTON FEATURES
    play: (state) => {
      if (state.runningState.status === RunningStatus.FINISHED) {
        state.routeMap.map.forEach((row) => {
          row.forEach((position) => {
            if (position.color !== MapColors.BG_WALL) {
              position.color = MapColors.BG_ROUTE;
            }
          });
        });

        state.runningState.currentStep = -1;
      }

      if (state.runningState.status !== RunningStatus.RUNNING) {
        state.runningState.status = RunningStatus.RUNNING;
      }

      state.runningState.currentStep++;

      state.settingParams.disabled = true;
    },

    // BUTTON FEATURES
    pause: (state) => {
      state.runningState.status = RunningStatus.PAUSED;
      state.settingParams.disabled = false;
    },

    // BUTTON FEATURES
    next: (state) => {
      if (state.runningState.status === RunningStatus.FINISHED) {
        state.routeMap.map.forEach((row) => {
          row.forEach((position) => {
            if (position.color !== MapColors.BG_WALL) {
              position.color = MapColors.BG_ROUTE;
            }
          });
        });

        state.runningState.currentStep = 0;
      }

      if (
        state.runningState.status !== RunningStatus.RUNNING &&
        state.runningState.currentStep < state.runningState.findingSteps.length - 1 &&
        state.runningState.currentStep >= 0
      ) {
        if (state.runningState.currentStep >= 1)
          state.routeMap.map[state.runningState.findingSteps[state.runningState.currentStep - 1].row][
            state.runningState.findingSteps[state.runningState.currentStep - 1].col
          ].color = MapColors.BG_VISITED;

        state.routeMap.map[state.runningState.findingSteps[state.runningState.currentStep].row][
          state.runningState.findingSteps[state.runningState.currentStep].col
        ].color = MapColors.BG_VISITED;

        state.runningState.currentStep++;

        state.routeMap.map[state.runningState.findingSteps[state.runningState.currentStep].row][
          state.runningState.findingSteps[state.runningState.currentStep].col
        ].color = MapColors.BG_CURRENT;

        state.runningState.status = RunningStatus.PAUSED;
      }
    },

    // BUTTON FEATURES
    prev: (state) => {
      if (state.runningState.status === RunningStatus.FINISHED) {
        state.routeMap.map.forEach((row) => {
          row.forEach((position) => {
            if (position.color === MapColors.BG_PATH) {
              position.color = MapColors.BG_VISITED;
            }
          });
        });
        state.runningState.currentStep = state.runningState.findingSteps.length;
      }

      if (
        state.runningState.status !== RunningStatus.RUNNING &&
        state.runningState.currentStep > 0 &&
        state.runningState.currentStep <= state.runningState.findingSteps.length
      ) {
        if (state.runningState.currentStep + 1 < state.runningState.findingSteps.length)
          state.routeMap.map[state.runningState.findingSteps[state.runningState.currentStep + 1].row][
            state.runningState.findingSteps[state.runningState.currentStep + 1].col
          ].color = MapColors.BG_ROUTE;

        if (state.runningState.currentStep < state.runningState.findingSteps.length)
          state.routeMap.map[state.runningState.findingSteps[state.runningState.currentStep].row][
            state.runningState.findingSteps[state.runningState.currentStep].col
          ].color = MapColors.BG_ROUTE;

        state.runningState.currentStep--;

        state.routeMap.map[state.runningState.findingSteps[state.runningState.currentStep].row][
          state.runningState.findingSteps[state.runningState.currentStep].col
        ].color = MapColors.BG_CURRENT;

        state.runningState.status = RunningStatus.PAUSED;
      }
    },

    // BUTTON FEATURES
    reset: (state) => {
      state.routeMap.map = randomMap(
        state.settingParams.rows,
        state.settingParams.cols,
        state.settingParams.obstacleDensity
      );

      state.routeMap = checkAndResetStartEndPosition(state.routeMap);

      const { routeMap, runningState } = pathFindingAlgo(
        state.settingParams.pathFindingAlgo,
        state.routeMap,
        state.runningState
      );
      state.routeMap = routeMap;
      state.runningState = runningState;
    },

    setRouteColor: (state, action: PayloadAction<Position>) => {
      if (
        action.payload.row >= 0 &&
        action.payload.row < state.routeMap.map.length &&
        action.payload.col >= 0 &&
        action.payload.col < state.routeMap.map[0].length
      )
        state.routeMap.map[action.payload.row][action.payload.col].color = MapColors.BG_ROUTE;
    },

    setVisitedColor: (state, action: PayloadAction<Position>) => {
      if (
        action.payload.row >= 0 &&
        action.payload.row < state.routeMap.map.length &&
        action.payload.col >= 0 &&
        action.payload.col < state.routeMap.map[0].length
      )
        state.routeMap.map[action.payload.row][action.payload.col].color = MapColors.BG_VISITED;
    },

    setCurrentColor: (state, action: PayloadAction<Position>) => {
      if (
        action.payload.row >= 0 &&
        action.payload.row < state.routeMap.map.length &&
        action.payload.col >= 0 &&
        action.payload.col < state.routeMap.map[0].length
      )
        state.routeMap.map[action.payload.row][action.payload.col].color = MapColors.BG_CURRENT;
    },

    setNeighborColor: (state, action: PayloadAction<Position>) => {
      if (
        action.payload.row >= 0 &&
        action.payload.row < state.routeMap.map.length &&
        action.payload.col >= 0 &&
        action.payload.col < state.routeMap.map[0].length
      )
        state.routeMap.map[action.payload.row][action.payload.col].color = MapColors.BG_NEIGHBOR;
    },

    setPathColor: (state, action: PayloadAction<Position>) => {
      if (
        action.payload.row >= 0 &&
        action.payload.row < state.routeMap.map.length &&
        action.payload.col >= 0 &&
        action.payload.col < state.routeMap.map[0].length
      )
        state.routeMap.map[action.payload.row][action.payload.col].color = MapColors.BG_PATH;
    },

    incrementCurrentIndex: (state) => {
      state.runningState.currentStep++;
    },

    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.settingParams.disabled = action.payload;
    },

    stop: (state) => {
      state.runningState.status = RunningStatus.FINISHED;
      state.settingParams.disabled = false;
    },
  },
});

export const {
  editCols,
  editRows,
  editObstacleDensity,
  setPathFindingAlgo,
  setDelay,
  setDisabled,
  editTouchPosition,
  moveStartPosition,
  moveEndPosition,
  setRouteColor,
  setVisitedColor,
  setCurrentColor,
  setNeighborColor,
  setPathColor,
  incrementCurrentIndex,
  play,
  pause,
  stop,
  next,
  prev,
  reset,
} = pathFindingSlice.actions;

export default pathFindingSlice.reducer;
