import { combineReducers, configureStore } from '@reduxjs/toolkit';

import pathFindingReducer from 'pages/path-finding-page/states/pathFindingSlice';
import sortingReducer from 'pages/sorting-page/states/sortingSlice';

const allReducers = combineReducers({
  sorting: sortingReducer,
  pathFinding: pathFindingReducer,
});

const store = configureStore({
  reducer: allReducers,
});

export default store;
export type RootState = ReturnType<typeof allReducers>;
