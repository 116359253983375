import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import getNeighborRoutes from './getNeighborRoutes';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';

export default function setPath(routeMap: RouteMap) {
  if (routeMap.endPosition && routeMap.startPosition) {
    routeMap.endPosition.value = routeMap.map[routeMap.endPosition.row][routeMap.endPosition.col].value;
    routeMap.path.push(routeMap.endPosition);

    let currentPosition = routeMap.endPosition;

    whileloop: while (true) {
      const neighborRoutes = getNeighborRoutes(currentPosition, routeMap);

      for (let i = 0; i < neighborRoutes.length; i++) {
        if (isSamePosition(neighborRoutes[i], routeMap.startPosition)) {
          routeMap.path.push(neighborRoutes[i]);
          break whileloop;
        }
      }

      const neighborVisited = neighborRoutes.filter((position) => position.value !== MapValues.ROUTE);

      if (neighborVisited.length === 0) break;

      const minDistance = getMinPositionValue(neighborVisited);

      const nextPosition = neighborVisited.find((position) => position.value === minDistance);
      if (nextPosition) {
        routeMap.path.push(nextPosition);
        currentPosition = nextPosition;
      }
    }

    routeMap.path.reverse();
  }
}

function getMinPositionValue(positions: Position[]) {
  return Math.min(...positions.map((position) => position.value));
}
