import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';

export default function getNeighborRoutes(currentPosition: Position, routeMap: RouteMap) {
  const neighborRoutes: Position[] = [];
  let neighborPosition: Position;

  if (routeMap.endPosition) {
    for (let rowIncrement = -1; rowIncrement < 2; rowIncrement++) {
      for (let colIncrement = -1; colIncrement < 2; colIncrement++) {
        if (
          (rowIncrement !== 0 || colIncrement !== 0) &&
          currentPosition.row + rowIncrement >= 0 &&
          currentPosition.row + rowIncrement < routeMap.map.length &&
          currentPosition.col + colIncrement >= 0 &&
          currentPosition.col + colIncrement < routeMap.map[0].length
        ) {
          neighborPosition = routeMap.map[currentPosition.row + rowIncrement][currentPosition.col + colIncrement];
          checkAndPushNeighborPosition();
        }
      }
    }
  }

  return neighborRoutes;

  function checkAndPushNeighborPosition() {
    if (neighborPosition.value !== MapValues.WALL) neighborRoutes.push(neighborPosition);
  }
}
