import ButtonPause from 'pages/path-finding-page/components/features/buttons/ButtonPause';
import ButtonPlay from 'pages/path-finding-page/components/features/buttons/ButtonPlay';
import RunningStatus from 'pages/path-finding-page/config/enums/RunningStatus';
import { selectRunningState } from 'pages/path-finding-page/states/selectors';
import { useSelector } from 'react-redux';

function PlayPauseSwitch() {
  const runningState = useSelector(selectRunningState);
  return runningState.status === RunningStatus.RUNNING ? (
    <ButtonPause />
  ) : (
    <ButtonPlay />
  );
}

export default PlayPauseSwitch;
