import ButtonNext from 'pages/path-finding-page/components/features/buttons/ButtonNext';
import ButtonPrev from 'pages/path-finding-page/components/features/buttons/ButtonPrev';
import ButtonReset from 'pages/path-finding-page/components/features/buttons/ButtonReset';
import PlayPauseSwitch from 'pages/path-finding-page/components/features/buttons/PlayPauseSwitch';

function ControllerButtons() {
  return (
    <div className='flex flex-col order-first md:order-none'>
      <div className='flex justify-center items-center mt-6 space-x-5'>
        <ButtonPrev />
        <PlayPauseSwitch />
        <ButtonNext />
      </div>
      <div className='flex justify-center my-3'>
        <ButtonReset />
      </div>
    </div>
  );
}

export default ControllerButtons;
