import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { PauseIcon } from 'pages/sorting-page/config/Icons';
import { pauseTimer } from 'pages/sorting-page/states/sortingSlice';
import { selectTimer } from 'pages/sorting-page/states/selectors';

function ButtonPause() {
  const dispatch = useDispatch();
  const timer = useSelector(selectTimer);

  const handleOnClickPause = () => {
    if (timer.intervalId) clearInterval(timer.intervalId);
    dispatch(pauseTimer());
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<PauseIcon color='primary' />}
      title='Pause'
      onClick={handleOnClickPause}
    />
  );
}

export default ButtonPause;
