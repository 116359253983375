import SortingArray from 'pages/sorting-page/config/interfaces/SortingArray';
import swap from 'pages/sorting-page/config/sorting-algorithms/swap';

const bubbleSort = (arr: SortingArray) => {
  const len = arr.sortedArray.length;
  for (let i = 0; i < len; i++) {
    for (let j = 0; j < len - i; j++) {
      if (arr.sortedArray[j] > arr.sortedArray[j + 1]) {
        swap(arr, j, j + 1);
      }
    }
  }
};

export default bubbleSort;
