import Button from 'common/components/Button';
import { PlayIcon } from 'pages/path-finding-page/config/Icons';
import { play } from 'pages/path-finding-page/states/pathFindingSlice';
import { useDispatch } from 'react-redux';

function ButtonPlay() {
  const dispatch = useDispatch();

  const handleOnClickPlay = () => {
    dispatch(play());
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<PlayIcon color='primary' />}
      title='Play'
      onClick={handleOnClickPlay}
    />
  );
}

export default ButtonPlay;
