import { HomeIcon } from 'pages/home-page/config/Icons';
import { Link } from 'react-router-dom';
import { RouteIcon } from 'pages/path-finding-page/config/Icons';
import { SortIcon } from 'pages/sorting-page/config/Icons';

function Header() {
  return (
    <div className='flex flex-wrap place-items-center'>
      <div className='relative mx-auto'>
        <nav className='flex justify-between bg-sky-600 text-white w-screen'>
          <div className='px-5 xl:px-12 py-6 flex w-full items-center'>
            {/* <img
              className='h-9'
              src='./images/lightdevops-logo-transparent.png'
              alt='logo'
            /> */}
            {/* <!-- Nav Links --> */}
            <ul className='hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12'>
              <li>
                <Link to='/' className='hover:text-gray-200'>
                  Home
                </Link>
              </li>
              <li>
                <Link to='/sorting-algorithm' className='hover:text-gray-200'>
                  Sorting Algorithm
                </Link>
              </li>
              <li>
                <Link to='/path-finding' className='hover:text-gray-200'>
                  Path Finding
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- Responsive navbar --> */}
          <Link to='/' className='hover:text-gray-200 md:hidden flex mr-12 items-center'>
            <HomeIcon />
          </Link>
          <Link to='/sorting-algorithm' className='hover:text-gray-200 navbar-burger self-center mr-12 md:hidden'>
            <SortIcon />
          </Link>
          <Link to='/path-finding' className='hover:text-gray-200 navbar-burger self-center mr-12 md:hidden'>
            <RouteIcon />
          </Link>
        </nav>
      </div>
    </div>
  );
}

export default Header;
