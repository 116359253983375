import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import IndexSwap from 'pages/sorting-page/config/interfaces/IndexSwap';
import { MIN_HEIGHT } from 'pages/sorting-page/config/Constants';
import SettingParams from 'pages/sorting-page/config/interfaces/SettingParams';
import SortAlgo from 'pages/sorting-page/config/enums/SortAlgo';
import TimerMode from 'pages/sorting-page/config/enums/TimerStatus';
import bubbleSort from 'pages/sorting-page/config/sorting-algorithms/bubbleSort';
import heapSort from 'pages/sorting-page/config/sorting-algorithms/heapSort';
import { initialState } from 'pages/sorting-page/states/initialStates';
import insertionSort from 'pages/sorting-page/config/sorting-algorithms/insertionSort';
import quickSort from 'pages/sorting-page/config/sorting-algorithms/quickSort';

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    setColumns: (state, action: PayloadAction<number>) => {
      state.settingParams.columns = action.payload;
    },
    setMaxHeight: (state, action: PayloadAction<number>) => {
      state.settingParams.diffHeight =
        action.payload - state.settingParams.maxHeight;
      state.settingParams.maxHeight = action.payload;
    },
    setWidth: (state, action: PayloadAction<number>) => {
      state.settingParams.width = action.payload;
    },
    setSortAlgo: (state, action: PayloadAction<SortAlgo>) => {
      state.settingParams.sortAlgo = action.payload;
    },
    setDelay: (state, action: PayloadAction<number>) => {
      state.settingParams.delay = action.payload;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.settingParams.disabled = action.payload;
    },
    deleteLastElement: (state) => {
      state.sortingArray.initArray.pop();
    },
    addElement: (state, action: PayloadAction<number>) => {
      state.sortingArray.initArray.push(action.payload);
    },
    sortArray: (state, action: PayloadAction<SettingParams>) => {
      state.sortingArray.sortedArray = [];
      state.sortingArray.indexSwapArray = [];
      state.sortingArray.currentIndexSwap = 0;

      state.sortingArray.initArray.forEach((element) => {
        state.sortingArray.sortedArray.push(element);
      });

      switch (action.payload.sortAlgo) {
        case SortAlgo.QuickSort:
          quickSort(
            state.sortingArray,
            0,
            state.sortingArray.initArray.length - 1
          );
          break;
        case SortAlgo.BubbleSort:
          bubbleSort(state.sortingArray);
          break;
        case SortAlgo.HeapSort:
          heapSort(state.sortingArray);
          break;
        case SortAlgo.InsertionSort:
          insertionSort(state.sortingArray);
          break;
        default:
          break;
      }
    },
    resetArray(state, action: PayloadAction<SettingParams>) {
      state.sortingArray.initArray = Array.from(
        { length: action.payload.columns },
        () =>
          Math.floor(
            Math.random() * (action.payload.maxHeight - MIN_HEIGHT) + MIN_HEIGHT
          )
      );
      state.sortingArray.sortedArray = [];
      state.sortingArray.indexSwapArray = [];
      state.sortingArray.currentIndexSwap = 0;
    },
    swapIndex(state, action: PayloadAction<IndexSwap>) {
      const { firstIndex, secondIndex } = action.payload;
      const temp = state.sortingArray.initArray[firstIndex];
      state.sortingArray.initArray[firstIndex] =
        state.sortingArray.initArray[secondIndex];
      state.sortingArray.initArray[secondIndex] = temp;
    },
    setCurrentIndexSwap(state, action: PayloadAction<number>) {
      state.sortingArray.currentIndexSwap = action.payload;
    },
    preStart(state) {
      state.sortingArray.sortedArray = [];
      state.sortingArray.indexSwapArray = [];
      state.sortingArray.currentIndexSwap = 0;
    },
    prestartTimer: (state) => {
      state.timer.timePaused = 0;
      state.timer.startTime = new Date().getTime();
      state.timer.endTime = new Date().getTime();
    },
    resumeTimer: (state) => {
      state.timer.timePaused += new Date().getTime() - state.timer.endTime;
      state.timer.endTime = new Date().getTime();
    },
    runTimer: (state, action?: PayloadAction<NodeJS.Timer | null>) => {
      state.timer.endTime = new Date().getTime();

      if (state.timer.intervalId === null && action)
        state.timer.intervalId = action.payload;
    },
    nextTimer: (state) => {
      state.timer.endTime = new Date().getTime();
    },
    pauseTimer: (state) => {
      state.timer.status = TimerMode.Paused;
      state.timer.endTime = new Date().getTime();
      state.timer.intervalId = null;
    },
    resetTimer: (state) => {
      state.timer.status = TimerMode.Stopped;
      state.timer.timePaused = 0;
      state.timer.startTime = 0;
      state.timer.endTime = 0;
      state.timer.intervalId = null;
    },
    clearIntervalId: (state) => {
      state.timer.intervalId = null;
    },
    setEndTime(state, action: PayloadAction<number>) {
      state.timer.endTime = action.payload;
    },
    setTimerStatus: (state, action: PayloadAction<TimerMode>) => {
      state.timer.status = action.payload;
    },
  },
});

export const {
  setColumns,
  setMaxHeight,
  setSortAlgo,
  setDelay,
  setWidth,
  setDisabled,
  deleteLastElement,
  addElement,
  sortArray,
  resetArray,
  swapIndex,
  setCurrentIndexSwap,
  preStart,
  prestartTimer,
  runTimer,
  nextTimer,
  pauseTimer,
  resumeTimer,
  resetTimer,
  clearIntervalId,
  setEndTime,
  setTimerStatus,
} = sortingSlice.actions;
export default sortingSlice.reducer;
