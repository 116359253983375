enum MapColors {
  BG_ROUTE = 'bg-white',
  BG_WALL = 'bg-black',
  BG_MAP = 'bg-sky-600',
  BG_VISITED = 'bg-gray-400',
  BG_PATH = 'bg-sky-300',
  BG_CURRENT = 'bg-amber-500',
  BG_NEIGHBOR = 'bg-amber-300',
  BG_START_POSITION = 'bg-green-400',
  BG_END_POSITION = 'bg-amber-400',
  BORDER_BOX = 'border-sky-600',
  BG_TOUCH_POSITION = 'bg-red-500',
}
export default MapColors;
