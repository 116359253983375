import { MAX_DENSITY } from 'pages/path-finding-page/config/Constants';
import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import isSamePosition from './isSamePosition';

const randomBinaryValue = (obstacleDensity: number) => {
  return Math.round((MAX_DENSITY * Math.random()) / obstacleDensity) === 0 ? -1 : 0; // -1 is wall; 0 is route
};

export function randomPosition(obstacleDensity: number, row: number, col: number) {
  const value = randomBinaryValue(obstacleDensity);
  const position = {
    row,
    col,
    value,
    color: value === -1 ? MapColors.BG_WALL : MapColors.BG_ROUTE,
    visited: false,
  } as Position;
  return position;
}

export function randomMap(rows: number, cols: number, obstacleDensity: number) {
  let matrix = [] as Position[][];
  for (let i = 0; i < rows; i++) {
    let row = [] as Position[];
    for (let j = 0; j < cols; j++) {
      const position = randomPosition(obstacleDensity, i, j);
      row.push(position);
    }
    matrix.push(row);
  }
  return matrix;
}

export function randomStartEndPosition(matrix: Position[][], startOrEndPosition?: Position | null) {
  const routePositions = matrix.flat().filter((position) => position.value !== MapValues.WALL);
  const hasRoute = routePositions.length > 0;
  const hasMoreThanTwoRoute = routePositions.length >= 2;

  let found = false;
  while (hasRoute) {
    let row = Math.floor(Math.random() * matrix.length);
    let col = Math.floor(Math.random() * matrix[0].length);

    if (matrix[row][col].value !== MapValues.WALL) {
      if (startOrEndPosition && hasMoreThanTwoRoute) {
        if (!isSamePosition(matrix[row][col], startOrEndPosition)) found = true;
      } else found = true;
    }

    if (found)
      return {
        row,
        col,
        color: matrix[row][col].color,
        value: matrix[row][col].value,
        visited: false,
      } as Position;
  }

  return null;
}
