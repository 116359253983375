import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import distanceBetweenTwoPosition from 'pages/path-finding-page/config/functions/distanceBetweenTwoPosition';

export default function isCurrentPositionOptimized(currentPosition: Position, prevPosition: Position) {
  return !(
    currentPosition.value === MapValues.ROUTE ||
    currentPosition.value > prevPosition.value + distanceBetweenTwoPosition(currentPosition, prevPosition)
  );
}
