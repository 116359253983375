import { IconButton, Tooltip } from '@mui/material';

interface Props {
  bgColor: string;
  onClick: Function;
  icon: any;
  title: string;
  disabled?: boolean;
}

function Button({ bgColor, onClick, icon, title, disabled }: Props) {
  return (
    <div className={`${bgColor} rounded-lg flex justify-center items-center w-16 h-16 md:w-auto md:h-auto`}>
      <Tooltip title={title}>
        <IconButton disabled={disabled ? disabled : false} onClick={() => onClick()}>
          {icon}
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default Button;
