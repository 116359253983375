import Position from 'pages/path-finding-page/config/interfaces/Position';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import RunningState from 'pages/path-finding-page/config/interfaces/RunningState';
import getNeighborRoutes from 'pages/path-finding-page/config/path-finding-algorithms/getNeighborRoutes';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';
import optimizeDistance from 'pages/path-finding-page/config/path-finding-algorithms/optimizeDistance';

export default function dijsktraAlgo(routeMap: RouteMap, runningState: RunningState) {
  const queue = [] as Position[];

  if (routeMap.startPosition) queue.push(routeMap.startPosition);

  while (true) {
    const currentIndex = getMinIndex(queue);

    if (currentIndex >= 0) {
      const currentPosition = queue[currentIndex];
      currentPosition.visited = true;

      runningState.findingSteps.push(currentPosition);

      if (isSamePosition(currentPosition, routeMap.endPosition)) break;

      const neighborRoutes = getNeighborRoutes(currentPosition, routeMap);

      neighborRoutes.forEach((neighborPosition) => {
        if (!isSamePosition(neighborPosition, routeMap.startPosition)) {
          const inQueue = neighborPosition.value > 0;

          optimizeDistance(neighborPosition, currentPosition);

          if (!inQueue) queue.push(neighborPosition);
          else updatePosition(neighborPosition, queue);
        }
      });
    } else {
      break;
    }
  }

  return { routeMap, runningState };
}

function getMinIndex(queue: Position[]) {
  let minDistance = Number.MAX_VALUE;
  let minIndex = -1;

  const len = queue.length;
  for (let i = 0; i < len; i++) {
    if (queue[i].visited === false && queue[i].value < minDistance) {
      minDistance = queue[i].value;
      minIndex = i;
    }
  }
  return minIndex;
}

function updatePosition(position: Position, queue: Position[]) {
  let len = queue.length;
  for (let i = 0; i < len; i++) {
    if (isSamePosition(position, queue[i])) {
      if (queue[i].value > position.value) queue[i].value = position.value;
      break;
    }
  }
}
