import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import Position from 'pages/path-finding-page/config/interfaces/Position';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';
import { randomStartEndPosition } from 'pages/path-finding-page/config/functions/random';

export default function checkAndResetStartEndPosition(routeMap: RouteMap) {
  if (
    routeMap.startPosition === null ||
    isPositionOutOfMap(routeMap.startPosition, routeMap.map) ||
    isWall(routeMap.startPosition, routeMap.map)
  )
    routeMap.startPosition = randomStartEndPosition(routeMap.map, routeMap.endPosition);

  if (
    routeMap.endPosition === null ||
    isPositionOutOfMap(routeMap.endPosition, routeMap.map) ||
    isWall(routeMap.endPosition, routeMap.map) ||
    isSamePosition(routeMap.startPosition, routeMap.endPosition)
  )
    routeMap.endPosition = randomStartEndPosition(routeMap.map, routeMap.startPosition);

  return routeMap;
}

function isPositionOutOfMap(position: Position, map: Position[][]) {
  return position.row >= map.length || position.col >= map[0].length;
}

function isWall(position: Position, map: Position[][]) {
  return map[position.row][position.col].value === MapValues.WALL;
}
