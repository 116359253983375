import SortingArray from 'pages/sorting-page/config/interfaces/SortingArray';
import swap from 'pages/sorting-page/config/sorting-algorithms/swap';

const insertionSort = (arr: SortingArray) => {
  const len = arr.sortedArray.length;
  for (let i = 1; i < len; i++) {
    let j = i;
    while (j > 0 && arr.sortedArray[j] < arr.sortedArray[j - 1]) {
      swap(arr, j, j - 1);
      j--;
    }
  }
};

export default insertionSort;
