import { selectSettingParams } from 'pages/sorting-page/states/selectors';
import { useSelector } from 'react-redux';

interface Props {
  height: number;
  color: string;
  borderColor?: string;
}

function Column({ height, color, borderColor }: Props) {
  const settingParams = useSelector(selectSettingParams);
  return (
    <div
      className={`flex justify-end border-t-4 border-x-4 ${borderColor} ${color}`}
      style={{
        width: `${settingParams.width}px`,
        height: `${height}px`,
      }}></div>
  );
}

export default Column;
