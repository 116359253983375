import {
  MAX_DELAY,
  MAX_HEIGHT,
  MAX_WIDTH,
  MIN_HEIGHT,
  MIN_WIDTH,
} from 'pages/sorting-page/config/Constants';
import {
  selectSettingParams,
  selectTimer,
} from 'pages/sorting-page/states/selectors';
import {
  setColumns,
  setDelay,
  setMaxHeight,
  setSortAlgo,
  setWidth,
} from 'pages/sorting-page/states/sortingSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ChangeEvent } from 'react';
import Input from 'common/components/Input';
import Select from 'common/components/Select';
import SortAlgo from 'pages/sorting-page/config/enums/SortAlgo';

function SettingBar() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);
  const timer = useSelector(selectTimer);

  return (
    <div className='flex md:flex-col items-start md:items-center space-x-5 md:space-x-0'>
      <Input
        label='Columns'
        placeholder='number of column'
        type='number'
        value={settingParams.columns}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch(
            setColumns(e.target.value === '' ? 0 : e.target.valueAsNumber)
          );
        }}
        disabled={settingParams.disabled}
        min={0}
      />
      <Input
        label='Max height'
        type='range'
        min={MIN_HEIGHT}
        max={MAX_HEIGHT}
        value={settingParams.maxHeight}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setMaxHeight(e.target.valueAsNumber))
        }
        disabled={settingParams.disabled}
      />
      <Input
        label='Width'
        type='range'
        min={MIN_WIDTH}
        max={MAX_WIDTH}
        value={settingParams.width}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setWidth(e.target.valueAsNumber))
        }
      />
      <Input
        label='Speed'
        type='range'
        min={-MAX_DELAY}
        max={0}
        value={-settingParams.delay}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setDelay(-e.target.valueAsNumber))
        }
      />
      <Select
        label='Sorting Algorithm'
        options={Object.keys(SortAlgo)}
        defaultValue={settingParams.sortAlgo}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(
            setSortAlgo(SortAlgo[e.target.value as keyof typeof SortAlgo])
          )
        }
        disabled={settingParams.disabled}
      />
    </div>
  );
}

export default SettingBar;
