import {
  resetArray,
  resetTimer,
  setDisabled,
} from 'pages/sorting-page/states/sortingSlice';
import {
  selectSettingParams,
  selectTimer,
} from 'pages/sorting-page/states/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { ResetIcon } from 'pages/sorting-page/config/Icons';

function ButtonReset() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);
  const timer = useSelector(selectTimer);

  const handleOnClickReset = () => {
    dispatch(setDisabled(false));
    dispatch(resetArray(settingParams));
    if (timer.intervalId) clearInterval(timer.intervalId);
    dispatch(resetTimer());
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<ResetIcon color='error' />}
      title='Reset'
      onClick={handleOnClickReset}
    />
  );
}

export default ButtonReset;
