import { MAX_DELAY, MAX_DENSITY, MIN_DENSITY, MIN_ROW_COL } from 'pages/path-finding-page/config/Constants';
import {
  editCols,
  editObstacleDensity,
  editRows,
  setDelay,
  setPathFindingAlgo,
} from 'pages/path-finding-page/states/pathFindingSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ChangeEvent } from 'react';
import Input from 'common/components/Input';
import PathFindingAlgo from 'pages/path-finding-page/config/enums/PathFindingAlgo';
import Select from 'common/components/Select';
import { selectSettingParams } from 'pages/path-finding-page/states/selectors';

function SettingBar() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);

  return (
    <div className='flex flex-col items-center md:items-start space-y-2 space-x-0'>
      <Input
        label='Columns'
        placeholder='number of column'
        type='number'
        value={settingParams.cols}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch(editCols(e.target.value === '' ? MIN_ROW_COL : e.target.valueAsNumber));
        }}
        disabled={settingParams.disabled}
        min={MIN_ROW_COL}
      />
      <Input
        label='Rows'
        placeholder='number of row'
        type='number'
        value={settingParams.rows}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch(editRows(e.target.value === '' ? MIN_ROW_COL : e.target.valueAsNumber));
        }}
        disabled={settingParams.disabled}
        min={MIN_ROW_COL}
      />
      <Input
        label='Obstacle Density'
        type='range'
        min={MIN_DENSITY}
        max={MAX_DENSITY}
        value={settingParams.obstacleDensity}
        onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(editObstacleDensity(e.target.valueAsNumber))}
        disabled={settingParams.disabled}
      />
      <Input
        label='Speed'
        type='range'
        min={-MAX_DELAY}
        max={0}
        value={-settingParams.delay}
        onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(setDelay(-e.target.valueAsNumber))}
      />
      <Select
        label='Algorithm'
        options={Object.keys(PathFindingAlgo)}
        defaultValue={settingParams.pathFindingAlgo}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setPathFindingAlgo(PathFindingAlgo[e.target.value as keyof typeof PathFindingAlgo]))
        }
        disabled={settingParams.disabled}
      />
    </div>
  );
}

export default SettingBar;
