enum Colors {
  BG_DEFAULT = 'bg-sky-600',
  BG_PREVIOUS_MOVE = 'bg-indigo-600',
  BG_CURRENT_MOVE = 'bg-amber-500',
  BG_FINISHED = 'bg-green-600',
  BORDER_DEFAULT = 'border-sky-600',
  BORDER_PREVIOUS_MOVE = 'border-indigo-600',
  BORDER_CURRENT_MOVE = 'border-amber-500',
  BORDER_FINISHED = 'border-green-600',
}
export default Colors;
