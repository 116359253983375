import BoxPosition from './BoxPosition';
import EndPosition from 'pages/path-finding-page/components/display/EndPosition';
import Legend from 'pages/path-finding-page/components/display/Legend';
import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import MapValues from 'pages/path-finding-page/config/enums/MapValues';
import StartPosition from 'pages/path-finding-page/components/display/StartPosition';

function LegendList() {
  return (
    <div className='flex flex-col space-y-5'>
      <div className='flex space-x-5'>
        <Legend legend={<StartPosition />} legendTitle='Start Position' />
        <Legend legend={<EndPosition />} legendTitle='End Position' />
        <Legend
          legend={
            <BoxPosition
              cell={{
                row: 0,
                col: 0,
                color: MapColors.BG_WALL,
                value: MapValues.WALL,
                visited: false,
              }}
            />
          }
          legendTitle='Obstacle'
        />

        <Legend
          legend={
            <BoxPosition
              cell={{
                row: 0,
                col: 0,
                color: MapColors.BG_ROUTE,
                value: MapValues.ROUTE,
                visited: false,
              }}
            />
          }
          legendTitle='Route'
        />
      </div>

      <div className='flex space-x-5'>
        <Legend
          legend={
            <BoxPosition
              cell={{
                row: 0,
                col: 0,
                color: MapColors.BG_CURRENT,
                value: MapValues.ROUTE,
                visited: false,
              }}
            />
          }
          legendTitle='Current Check'
        />

        <Legend
          legend={
            <BoxPosition
              cell={{
                row: 0,
                col: 0,
                color: MapColors.BG_VISITED,
                value: MapValues.ROUTE,
                visited: false,
              }}
            />
          }
          legendTitle='Visited'
        />

        <Legend
          legend={
            <BoxPosition
              cell={{
                row: 0,
                col: 0,
                color: MapColors.BG_PATH,
                value: 1,
                visited: false,
              }}
            />
          }
          legendTitle='Shortest Path'
        />
      </div>
    </div>
  );
}

export default LegendList;
