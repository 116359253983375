import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from 'common/scenes/Header';
import HomePage from 'pages/home-page/HomePage';
import PathFindingPage from 'pages/path-finding-page/components/display/PathFindingPage';
import { Provider } from 'react-redux';
import SortingPage from 'pages/sorting-page/components/display/SortingPage';
import store from 'global-states/store';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className='flex flex-col'>
          <Header />
          <div className='flex'>
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/sorting-algorithm' element={<SortingPage />} />
              <Route path='/path-finding' element={<PathFindingPage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
