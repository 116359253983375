import SortingArray from 'pages/sorting-page/config/interfaces/SortingArray';
import swap from 'pages/sorting-page/config/sorting-algorithms/swap';

const heapSort = (arr: SortingArray) => {
  const len = arr.sortedArray.length;
  for (let i = Math.floor(len / 2); i >= 0; i--) {
    heapify(arr, len, i);
  }
  for (let i = len - 1; i > 0; i--) {
    swap(arr, 0, i);
    heapify(arr, i, 0);
  }
};

const heapify = (arr: SortingArray, len: number, i: number) => {
  let largest = i;
  const left = 2 * i + 1;
  const right = 2 * i + 2;
  if (left < len && arr.sortedArray[left] > arr.sortedArray[largest]) {
    largest = left;
  }
  if (right < len && arr.sortedArray[right] > arr.sortedArray[largest]) {
    largest = right;
  }
  if (largest !== i) {
    swap(arr, i, largest);
    heapify(arr, len, largest);
  }
};

export default heapSort;
