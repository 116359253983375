import {
  incrementCurrentIndex,
  setCurrentColor,
  setNeighborColor,
  setPathColor,
  setVisitedColor,
  stop,
} from 'pages/path-finding-page/states/pathFindingSlice';
import { selectRouteMap, selectRunningState, selectSettingParams } from 'pages/path-finding-page/states/selectors';
import { useDispatch, useSelector } from 'react-redux';

import BoxPosition from './BoxPosition';
import EndPosition from 'pages/path-finding-page/components/display/EndPosition';
import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import RunningStatus from 'pages/path-finding-page/config/enums/RunningStatus';
import StartPosition from 'pages/path-finding-page/components/display/StartPosition';
import isSamePosition from 'pages/path-finding-page/config/functions/isSamePosition';
import { useEffect } from 'react';

function RouteMapDisplay() {
  const dispatch = useDispatch();
  const routeMap = useSelector(selectRouteMap);
  const runningState = useSelector(selectRunningState);
  const settingParams = useSelector(selectSettingParams);

  useEffect(() => {
    if (
      runningState.currentStep >= 0 &&
      runningState.currentStep < runningState.findingSteps.length &&
      runningState.status === RunningStatus.RUNNING
    ) {
      setTimeout(() => {
        if (runningState.currentStep >= 0)
          dispatch(setCurrentColor(runningState.findingSteps[runningState.currentStep]));
        if (runningState.currentStep >= 1)
          dispatch(setVisitedColor(runningState.findingSteps[runningState.currentStep - 1]));
        if (runningState.currentStep >= 2)
          dispatch(setVisitedColor(runningState.findingSteps[runningState.currentStep - 2]));
      }, settingParams.delay);

      setTimeout(() => dispatch(incrementCurrentIndex()), 2 * settingParams.delay);
    } else if (runningState.currentStep === runningState.findingSteps.length) {
      dispatch(setVisitedColor(runningState.findingSteps[runningState.currentStep - 1]));

      routeMap.path.forEach((position) => setTimeout(() => dispatch(setPathColor(position)), settingParams.delay));

      setTimeout(() => dispatch(stop()), 2 * settingParams.delay);
    }
  }, [runningState.currentStep]);

  return (
    <div className='flex'>
      <div className={`flex-col justify-start items-center p-5 ${MapColors.BG_MAP}`}>
        {routeMap.map.map((row, rowIndex) => {
          return (
            <div key={rowIndex} className='flex justify-start items-center'>
              {row.map((cell, cellIndex) => {
                return (
                  <div key={cellIndex} className={`flex border-r border-b ${MapColors.BORDER_BOX}`}>
                    {routeMap.startPosition && isSamePosition(cell, routeMap.startPosition) ? (
                      <StartPosition />
                    ) : routeMap.endPosition && isSamePosition(cell, routeMap.endPosition) ? (
                      <EndPosition />
                    ) : (
                      <BoxPosition cell={cell} />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RouteMapDisplay;
