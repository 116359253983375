import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import RunningState from 'pages/path-finding-page/config/interfaces/RunningState';

function setMapColors(routeMap: RouteMap, runningState: RunningState) {
  runningState.findingSteps.forEach((currentPosition) => {
    routeMap.map[currentPosition.row][currentPosition.col].color = MapColors.BG_VISITED;
  });

  routeMap.path.forEach((path) => {
    routeMap.map[path.row][path.col].color = MapColors.BG_PATH;
  });
}

export default setMapColors;
