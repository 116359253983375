import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { NextIcon } from 'pages/path-finding-page/config/Icons';
import { next } from 'pages/path-finding-page/states/pathFindingSlice';
import { selectSettingParams } from 'pages/path-finding-page/states/selectors';

function ButtonNext() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);

  const handleOnClick = () => {
    dispatch(next());
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<NextIcon color='action' />}
      title='Next'
      onClick={handleOnClick}
      disabled={settingParams.disabled}
    />
  );
}

export default ButtonNext;
