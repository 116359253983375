import MapColors from 'pages/path-finding-page/config/enums/MapColors';
import { ReactElement } from 'react';

function Legend({
  legend,
  legendTitle,
}: {
  legend: ReactElement;
  legendTitle: string;
}) {
  return (
    <div className='flex items-center space-x-2 w-40'>
      <div className={`border ${MapColors.BORDER_BOX}`}>{legend}</div>
      <span>{legendTitle}</span>
    </div>
  );
}

export default Legend;
