import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { ResetIcon } from 'pages/path-finding-page/config/Icons';
import { reset } from 'pages/path-finding-page/states/pathFindingSlice';
import { selectSettingParams } from 'pages/path-finding-page/states/selectors';

function ButtonReset() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);

  const handleOnClick = () => {
    dispatch(reset());
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<ResetIcon color='error' />}
      title='Reset'
      onClick={handleOnClick}
      disabled={settingParams.disabled}
    />
  );
}

export default ButtonReset;
