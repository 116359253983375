import PathFindingAlgo from 'pages/path-finding-page/config/enums/PathFindingAlgo';
import RouteMap from 'pages/path-finding-page/config/interfaces/RouteMap';
import RunningState from 'pages/path-finding-page/config/interfaces/RunningState';
import dijsktraAlgo from './dijsktra-algo/dijsktraAlgo';
import lightDevopsAlgo from './lightdevops-algo/lightDevopsAlgo';
import resetState from './resetState';
import setMapColors from './setMapColors';
import setPath from './setPath';

export default function pathFindingAlgo(algo: PathFindingAlgo, routeMap: RouteMap, runningState: RunningState) {
  resetState(routeMap, runningState);

  switch (algo) {
    case PathFindingAlgo.LightDevops:
      lightDevopsAlgo(routeMap, runningState);
      break;
    case PathFindingAlgo.Dijkstra:
      dijsktraAlgo(routeMap, runningState);
      break;
    default:
      lightDevopsAlgo(routeMap, runningState);
  }

  setPath(routeMap);

  setMapColors(routeMap, runningState);

  return { routeMap, runningState };
}
