import SortAlgo from 'pages/sorting-page/config/enums/SortAlgo';

export const DEFAULT_NUMBER_COLUMNS: number = 50;

export const MAX_HEIGHT: number = 1000;
export const MIN_HEIGHT: number = 10;
export const DEFAULT_MAX_HEIGHT: number = 500;

export const MAX_WIDTH: number = 20;
export const MIN_WIDTH: number = 2;
export const DEFAULT_WIDTH: number = 10;

export const DEFAULT_SORT_ALGO: SortAlgo = SortAlgo.QuickSort;

export const MAX_DELAY: number = 1000;
export const DEFAULT_DELAY: number = 0;
