import ControllerButtons from 'pages/path-finding-page/components/features/ControllerButtons';
import SettingBar from 'pages/path-finding-page/components/features/SettingBar';

function SideBar() {
  return (
    <div className='order-last md:order-first md:h-screen items-center bg-sky-100 flex justify-center m-5'>
      <div className='p-5'>
        <div className='flex flex-col bg-white p-5 md:p-10 rounded-lg shadow-lg min-w-max'>
          <h1 className='hidden md:flex justify-center text-center text-2xl mb-6 text-gray-600 font-bold font-sans'>
            Setting
          </h1>
          <SettingBar />
          <ControllerButtons />
        </div>
      </div>
    </div>
  );
}

export default SideBar;
