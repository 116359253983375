import PathFindingAlgo from 'pages/path-finding-page/config/enums/PathFindingAlgo';

export const DEFAULT_ROW = 20;
export const DEFAULT_COL = 20;
export const MIN_ROW_COL = 1;

export const DEFAULT_PATH_FINDING_ALGO = PathFindingAlgo.LightDevops;

export const MAX_DELAY: number = 1000;
export const DEFAULT_DELAY: number = 0;

export const MAX_DENSITY = 5;
export const MIN_DENSITY = 1;
export const DEFAULT_DENSITY = 3;

export const BOX_PIXEL = 32;
export const BOX_SIZE = 'w-8 h-8';
