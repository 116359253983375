function HomePage() {
  return (
    <div className='h-screen w-screen bg-gradient-to-b from-sky-600 to-sky-100'>
      <div className='flex h-full w-full items-center justify-center container mx-auto px-8'>
        <div className='text-center'>
          <h1 className='text-3xl sm:text-5xl capitalize tracking-widest text-white lg:text-7xl'>
            Welcome to Algo App
          </h1>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
