import SortingArray from 'pages/sorting-page/config/interfaces/SortingArray';
import swap from 'pages/sorting-page/config/sorting-algorithms/swap';

const quickSort = (arr: SortingArray, low: number, high: number) => {
  if (low < high) {
    const pivot = partition(arr, low, high);
    quickSort(arr, low, pivot - 1);
    quickSort(arr, pivot + 1, high);
  }
};

const partition = (arr: SortingArray, low: number, high: number) => {
  const pivot = arr.sortedArray[high];
  let i = low - 1;
  for (let j = low; j < high; j++) {
    if (arr.sortedArray[j] < pivot) {
      i++;
      swap(arr, i, j);
    }
  }
  swap(arr, i + 1, high);
  return i + 1;
};

export default quickSort;
