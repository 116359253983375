import {
  pauseTimer,
  prestartTimer,
  resumeTimer,
  setCurrentIndexSwap,
  setDisabled,
  setTimerStatus,
  sortArray,
  swapIndex,
} from 'pages/sorting-page/states/sortingSlice';
import {
  selectSettingParams,
  selectSortingArray,
  selectTimer,
} from 'pages/sorting-page/states/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import { NextIcon } from 'pages/sorting-page/config/Icons';
import TimerStatus from 'pages/sorting-page/config/enums/TimerStatus';

function ButtonNext() {
  const dispatch = useDispatch();
  const settingParams = useSelector(selectSettingParams);
  const sortingArray = useSelector(selectSortingArray);
  const timer = useSelector(selectTimer);

  const handleOnClickNext = () => {
    switch (timer.status) {
      case TimerStatus.Stopped:
        dispatch(setDisabled(true));
        dispatch(prestartTimer());
        dispatch(setTimerStatus(TimerStatus.Paused));
        dispatch(sortArray(settingParams));
        setTimeout(() => dispatch(pauseTimer()));
        break;

      case TimerStatus.Paused:
        if (
          sortingArray.currentIndexSwap < sortingArray.indexSwapArray.length
        ) {
          dispatch(resumeTimer());
          dispatch(
            swapIndex(
              sortingArray.indexSwapArray[sortingArray.currentIndexSwap]
            )
          );
          dispatch(setCurrentIndexSwap(sortingArray.currentIndexSwap + 1));
          setTimeout(() => dispatch(pauseTimer()));
        }
        break;

      default:
        break;
    }
  };

  return (
    <Button
      bgColor='bg-gray-100'
      icon={<NextIcon color='action' />}
      title='Next swap'
      onClick={handleOnClickNext}
    />
  );
}

export default ButtonNext;
