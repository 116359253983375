import {
  DEFAULT_DELAY,
  DEFAULT_MAX_HEIGHT,
  DEFAULT_NUMBER_COLUMNS,
  DEFAULT_SORT_ALGO,
  DEFAULT_WIDTH,
  MIN_HEIGHT,
} from 'pages/sorting-page/config/Constants';

import SettingParams from 'pages/sorting-page/config/interfaces/SettingParams';
import SortingArray from 'pages/sorting-page/config/interfaces/SortingArray';
import SortingState from 'pages/sorting-page/config/interfaces/SortingState';
import Timer from 'pages/sorting-page/config/interfaces/Timer';
import TimerStatus from 'pages/sorting-page/config/enums/TimerStatus';

const initialStateSettingParams = {
  columns: DEFAULT_NUMBER_COLUMNS,
  maxHeight: DEFAULT_MAX_HEIGHT,
  diffHeight: 0,
  width: DEFAULT_WIDTH,
  delay: DEFAULT_DELAY,
  sortAlgo: DEFAULT_SORT_ALGO,
  disabled: false,
} as SettingParams;

const initialStateSortingArray = {
  initArray: Array.from({ length: DEFAULT_NUMBER_COLUMNS }, () =>
    Math.floor(Math.random() * (DEFAULT_MAX_HEIGHT - MIN_HEIGHT) + MIN_HEIGHT)
  ),
  sortedArray: [],
  indexSwapArray: [],
  currentIndexSwap: 0,
} as SortingArray;

const initialStateTimer = {
  startTime: 0,
  endTime: 0,
  intervalId: null,
  status: TimerStatus.Stopped,
  timePaused: 0,
} as Timer;

export const initialState = {
  settingParams: initialStateSettingParams,
  sortingArray: initialStateSortingArray,
  timer: initialStateTimer,
} as SortingState;
