interface Props {
  label: any;
  options: any[];
  defaultValue: string;
  onChange: Function;
  disabled?: boolean;
}

function Select({ label, options, defaultValue, onChange, disabled }: Props) {
  return (
    <div className='flex flex-col w-full items-center md:items-start md:mb-2'>
      <label className='text-gray-800 font-semibold block mb-1 text-md'>{label}</label>
      <select
        className='w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none'
        defaultValue={defaultValue}
        onChange={(e) => onChange(e)}
        disabled={disabled}>
        {options.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
